.mobile-menu {
    

    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: 1fr;
    
}

.hamburger {
    width: 35px;
    height: 5px;
    background-color: white;
    margin: 13px 10px;
}

.hamburger-button {
    all:unset;
background-color: black;
}

.menu-items {

    background-color: aqua;
    width: 100%;
    border: 1px solid aqua;
    height: 82vh;
}

.menu-item2 {
    margin: 70px;
    text-align: center;
  
}

.menu-bottom {
    text-align: center;
    color: black;
    background-color: aqua;
    border: 1px solid aqua;
}
