.menu-grid {
    display: grid;
    grid-template-columns: 1fr 10fr 1fr;

    width: 100%;
    background-color: black;
    align-items: center;
  

}


.menu-links {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  
    color: white;
    text-align: center;
font-size: 1.5em;

}

.banner {
    grid-column-start: 1;
    grid-column-end: 5;
}



.menu-links h5:hover{
    color:turquoise;
}

a{
all: unset;
}

